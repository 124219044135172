import React from "react"

// Components
import { Link, graphql } from "gatsby"
import Layout from "../components/layout";
import SEO from "../components/seo";

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext;
  const { edges, totalCount } = data.allMarkdownRemark;
  const title = `All “${tag}” Posts`;

  return (
    <Layout location={''} title={title}>
      <SEO title={tag} description={`All Posts related to the “${tag}” topic.`} />
      <div className="post__header">
        <h1>{title}</h1>
      </div>
      <ul>
        {edges.map(({ node }) => {
          const { title } = node.frontmatter,
            { slug } = node.fields;
          return (
            <li key={slug}>
              <Link to={`/posts/${slug}`}>{title}</Link>
            </li>
          )
        })}
      </ul>
      <Link to="/tags">All all topics</Link>
    </Layout>
  )
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
